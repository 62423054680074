// import external dependencies
/* eslint-disable */
import "jquery";

// Import everything from autoload
import "./autoload/browserdetect.js";

// import local dependencies
import Router from "./util/Router";
import common from "./routes/common";
import home from "./routes/home";
import about from "./routes/about";

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  about,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
