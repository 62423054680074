/* eslint-disable */
import { CountUp } from "countup.js";
import Swiper, { Pagination, Autoplay } from "swiper";
import Chart, { layouts } from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
  init() {
    var swiper = new Swiper(".home-swiper", {
      modules: [Pagination, Autoplay],
      slidesPerView: 1,
      draggable: true,
      loop: true,
      autoplay: {
        delay: 7000,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });

    function toggleFillOnHover(className) {
      const elements = document.querySelectorAll(className);
      elements.forEach((element) => {
        element.addEventListener("mouseover", () => {
          elements.forEach((el) => {
            el.style.fill = "#eb4461";
          });
        });
        element.addEventListener("mouseout", () => {
          elements.forEach((el) => {
            el.style.fill = "#fff";
          });
        });
      });
    }

    var eduChart;
    var workChart;
    var eduCasesIndex = 0;
    var workCasesIndex = 0;

    toggleFillOnHover(".mitrovice");
    toggleFillOnHover(".prishtine");
    toggleFillOnHover(".gjilan");
    toggleFillOnHover(".ferizaj");
    toggleFillOnHover(".prizren");
    toggleFillOnHover(".gjakove");
    toggleFillOnHover(".peje");

    // $(document).ready(function () {
    //   setTimeout(function () {
    //     $("#data-institution #policia-e-kosoves-1").prop("selected", true);
    //   }, 100);
    //   setTimeout(function () {
    //     $(".filter-submit input").click();
    //   }, 300);
    // });

    const mapWrapper = $("#map-wrapper__content");

    $(document).on("submit", "#data-filter__form", function (e) {
      e.preventDefault();
      var data = {};
      $.each($(this).serializeArray(), function (i, field) {
        data[field.name] = field.value;
      });

      jQuery.ajax({
        type: "post",

        url: admin_url,

        data: data,

        success: function (response) {
          mapWrapper.html(response);

          $(".filter-button").click(function () {
            $(".mobile-filtering").toggleClass("mobile-filtering__display");
            $("body").toggleClass("body-overflow");
          });

          $(".radio-title").click(function () {
            if ($(this).parent().find(".radio-wrapper").is(":hidden")) {
              $(this).parent().find(".radio-wrapper").slideDown("fast");
            } else {
              $(this).parent().find(".radio-wrapper").slideUp("fast");
            }
            $(this).find("svg").toggleClass("filter-arrow-down");
          });

          $(".filter-button__close").click(function () {
            $(".filter-submit input").trigger("click");
            $(".select-wrapper :input").attr("disabled", true);
          });

          $("path").on("click", function () {
            if (this.hasAttribute("data-city")) {
              var city = $(this).attr("data-city");
              $("#data-year").val(city);
              $("input[type=radio][value='" + city + "']").prop(
                "checked",
                true
              );
              $(".filter-submit input").trigger("click");
            }
          });

          $(".data-filter__select").on("change", function () {
            $(".filter-submit input").trigger("click");
          });

          var countUp = new CountUp("pr", prishtine, {
            enableScrollSpy: true,
          });
          countUp.start();
          countUp = new CountUp("gjk", gjakova, {
            enableScrollSpy: true,
          });
          countUp.start();
          countUp = new CountUp("pj", peja, {
            enableScrollSpy: true,
          });
          countUp.start();
          countUp = new CountUp("mi", mitrovica, {
            enableScrollSpy: true,
          });
          countUp.start();
          countUp = new CountUp("pz", prizren, {
            enableScrollSpy: true,
          });
          countUp.start();
          countUp = new CountUp("fe", ferizaj, {
            enableScrollSpy: true,
          });
          countUp.start();
          countUp = new CountUp("gji", gjilan, {
            enableScrollSpy: true,
          });
          countUp.start();
          countUp = new CountUp("totalcase", total, {
            enableScrollSpy: true,
          });
          countUp.start();

          countUp.handleScroll();

          toggleFillOnHover(".mitrovice");
          toggleFillOnHover(".prishtine");
          toggleFillOnHover(".gjilan");
          toggleFillOnHover(".ferizaj");
          toggleFillOnHover(".prizren");
          toggleFillOnHover(".gjakove");
          toggleFillOnHover(".peje");

          eduChart = undefined;
          attachEventListenersToTabs();
          attachEventListenersToQuestions();
        },
      });
    });

    var lastSelected = null;
    $(document).ready(function () {
      $("path").click(function () {
        if (this.hasAttribute("data-city")) {
          if (lastSelected != null) lastSelected.removeClass("active-city");
          var city = $(this).attr("data-city");
          $("#" + city).addClass("active-city");
          lastSelected = $("#" + city);
        }
      });
    });

    $(".radio-title").click(function () {
      if ($(this).parent().find(".radio-wrapper").is(":hidden")) {
        $(this).parent().find(".radio-wrapper").slideDown("fast");
      } else {
        $(this).parent().find(".radio-wrapper").slideUp("fast");
      }
      $(this).find("svg").toggleClass("filter-arrow-down");
    });

    $(".filter-button").click(function () {
      $(".mobile-filtering").toggleClass("mobile-filtering__display");
      $("body").toggleClass("body-overflow");
    });

    $("path").on("click", function () {
      if (this.hasAttribute("data-city")) {
        var city = $(this).attr("data-city");
        $("#data-year").val(city);
        $("input[type=radio][value='" + city + "']").prop("checked", true);
        $(".filter-submit input").trigger("click");
      }
    });

    $(".filter-button__close").click(function () {
      $(".filter-submit input").trigger("click");
      $(".select-wrapper :input").attr("disabled", true);
    });

    $(".data-filter__select").on("change", function () {
      $(".filter-submit input").trigger("click");
    });

    if(window.location.hash.slice(1) != ''){
      const tabData = window.location.hash.slice(1);

      $('#category-tabs').find('.catmenu-item-active').removeClass('catmenu-item-active');
      $(`a[data-tab="${tabData}"]`).addClass('catmenu-item-active');

      $('#map-data').addClass('hidden');
      $('#data-filter__form').addClass('hidden');
      $('#data-filter__form').next().addClass('hidden');
      $('#edu-filters').addClass('hidden');
      $('#graph-data').addClass('hidden');

      if(tabData === 'education'){
        $('#edu-filters').removeClass('hidden');
        $('#graph-data').removeClass('hidden');
        initChart(0, $('a.graph-circle').eq(0).attr('data-question'));
      }else if(tabData === 'violence'){
        $('#map-data').removeClass('hidden');
        $('#data-filter__form').removeClass('hidden');
        $('#data-filter__form').next().removeClass('hidden');
      }else if(tabData === 'work'){
        $('#work-filters').removeClass('hidden');
        $('#work-graph-data').removeClass('hidden');
        initWorkChart(0, $('a.work-graph-circle').eq(0).attr('data-question'));
      }
    }

    attachEventListenersToTabs();
    attachEventListenersToQuestions();

    function attachEventListenersToTabs(){
      $('#category-tabs a').on('click', function(e){
        const tabData = $(this).attr('data-tab');

        // if(tabData != 'education' && tabData != 'violence') return false;

        $(this).parent().find('.catmenu-item-active').removeClass('catmenu-item-active');
        $(this).addClass('catmenu-item-active');

        $('#map-data').addClass('hidden');
        $('#data-filter__form').addClass('hidden');
        $('#data-filter__form').next().addClass('hidden');
        $('#edu-filters').addClass('hidden');
        $('#graph-data').addClass('hidden');
        $('#work-filters').addClass('hidden');
        $('#work-graph-data').addClass('hidden');

        if(tabData === 'education'){
          $('#edu-filters').removeClass('hidden');
          $('#graph-data').removeClass('hidden');
          initChart(0, $('a.graph-circle').eq(0).attr('data-question'));
        }else if(tabData === 'violence'){
          $('#map-data').removeClass('hidden');
          $('#data-filter__form').removeClass('hidden');
          $('#data-filter__form').next().removeClass('hidden');
        }else if(tabData === 'work'){
          $('#work-filters').removeClass('hidden');
          $('#work-graph-data').removeClass('hidden');
          initWorkChart(0, $('a.work-graph-circle').eq(0).attr('data-question'));
        }
      });

      $('a.education-filter').on('click', function(e){
        e.preventDefault();
        $(this).parent().find('.tab-active').removeClass('tab-active');
        $(this).addClass('tab-active');
        let questionContent = '';
        let questionIndex = 0;
        let firstQuestion = '';
        eduCasesIndex = $(this).attr('data-edu-index');
        const casesData = chartData[eduCasesIndex];
        Object.keys(casesData).map((question) => {
          if(!questionIndex){
            firstQuestion = question;
            
          }
          questionContent +=`<a href="javascript:;" class="graph-circle" data-question="${question}">
            <div class="city-content question-content ${!questionIndex ? 'question-active' : ''}">
              <div class="question-text">${question}</div>
            </div>
          </a>`;
          questionIndex++;
        });
        $('#question-handler').html(questionContent);
        initChart(eduCasesIndex, firstQuestion);
        changeLabelsToChart(firstQuestion);
        attachEventListenersToQuestions();
      });
    }

    function attachEventListenersToQuestions() {
      $('a.graph-circle').on('click', function(e){
        e.preventDefault();
        $(this).parent().find('.question-active').removeClass('question-active');
        $(this).find('.question-content').addClass('question-active');
        const question = $(this).attr('data-question');
        changeLabelsToChart(question);
        initChart(eduCasesIndex, question);
      });
      $('a.work-graph-circle').on('click', function(e){
        e.preventDefault();
        $(this).parent().find('.work-question-active').removeClass('work-question-active');
        $(this).find('.work-question-content').addClass('work-question-active');
        const question = $(this).attr('data-question');
        workCasesIndex = $(this).attr('data-index');
        changeLabelsToChart(question, true);
        initWorkChart(workCasesIndex, question);
      });
    }

    function changeLabelsToChart(question, isWork = false){
      if(!isWork){

        $('#chart-data-label .toggle-option').eq(0).find('span').html(dataChartLabels[question]['f']);
        $('#chart-data-label .toggle-option').eq(1).find('span').html(dataChartLabels[question]['m']);
      }else{
        $('#work-chart-data-label .toggle-option').eq(0).find('span').html(workDataChartLabels[question]['f']);
        $('#work-chart-data-label .toggle-option').eq(1).find('span').html(workDataChartLabels[question]['m']);
      }
    }
    
    function initChart(index = 0, question = ''){
      const result = {};
      if(question !== ''){
        const cases = chartData[index][question];
        Object.keys(chartData[index][question]).map((year, k) => {
          if(!(year in result)){
            result[year] = {female: cases[year].female, male: cases[year].male};
            return;
          }
          result[year]['female'] += cases[year].female;
          result[year]['male'] += cases[year].male;
        });
      }else{
        Object.values(chartData[index]).map((cases, i) => {
          Object.keys(cases).map((year, k) => {
            if(!(year in result)){
              result[year] = {female: cases[year].female, male: cases[year].male};
              return;
            }
            result[year]['female'] += cases[year].female;
            result[year]['male'] += cases[year].male;
          });
        });
      }
      const data = {
        labels: Object.keys(result),
        datasets: [
          {
            label: 'Female',
            data: Object.values(result).map((mun) => mun['female']),
            backgroundColor: '#EB4461',
            stack: 'gender',
            borderRadius: 12,
          },
          {
            label: 'Male',
            data:  Object.values(result).map((mun) => mun['male'] * -1 ),
            backgroundColor: '#C8A8F1',
            stack: 'gender',
            borderRadius: 12,
          }
        ]
      };
      if(eduChart){
        eduChart.data.labels = data.labels;
        eduChart.data.datasets = data.datasets;
        eduChart.options.plugins.title.text = question;
        eduChart.update();

        return;
      }

      const config = {
        type: 'bar',
        data: data,
        plugins: [ChartDataLabels],
        layouts: {
          font:{
            family:"Neue Machina"
          }
        },
        options: {
          plugins: {
            title: {
              display: true,
              text: question,
              color: 'whitesmoke',
              font: {
                size: 18,
                family:"Neue Machina"
              },
              position: 'top',
              padding: {
                top: 20,  // Add padding to the top of the title
                bottom: 30  // Add padding to the bottom of the title
              },
            },
            tooltip: {
              callbacks: {
                label: function(context) {
                  let value = context.raw;
                  let positiveValue = Math.abs(value);
                  return positiveValue;
                }
              }
            },
            datalabels: {
              display: true,
              align: function(context) {
                var index = context.dataIndex;
                var value = context.dataset.data[index];
                return value > 0 ? 'end' : 'start';
              },
              anchor: function(context) {
                var index = context.dataIndex;
                var value = context.dataset.data[index];
                return value > 0 ? 'end' : 'start';
              },
              clamp: true, 
              formatter: function(value, context) {
                return value < 0 ? value * -1 : value;
              },
              font: {
                family:"Neue Machina"
              },
              padding: {
                bottom:10  // Add padding to the bottom of the title
              },
              color: 'whitesmoke'
            },
            legend:{
              display: false
            },
          },
          responsive: true,
          interaction: {
            intersect: false,
          },
          scales: {
            x: {
              ticks: {
                display: true,
                color: '#EB4461',
                font:{
                  family:"Neue Machina",
                  weight: 800
                },
                padding: 20,
                showLabelBackdrop: true,
                backdropColor: 'whitesmoke'
              },
              offset: true,
              stacked: true,
            },
            y: {
              ticks: {
                display: false,
                color: 'whitesmoke'
              },
              stacked: false
            },
          }
        }
      };
      eduChart = new Chart(document.getElementById('chart'), config);
    }

    function initWorkChart(workIndex = 0, question = ''){
      const result = {};
      const cases = workChartData[workIndex];
      Object.keys(workChartData[workIndex]).map((year, k) => {
        if(!(year in result)){
          result[year] = {female: cases[year].female, male: cases[year].male};
          return;
        }
        result[year]['female'] += cases[year].female;
        result[year]['male'] += cases[year].male;
      });
      
      const data = {
        labels: Object.keys(result),
        datasets: [
          {
            label: 'Female',
            data: Object.values(result).map((mun) => mun['female']),
            backgroundColor: '#8D79CB',
            stack: 'gender',
            borderRadius: 12,
          },
          {
            label: 'Male',
            data:  Object.values(result).map((mun) => mun['male'] * -1 ),
            backgroundColor: '#2698BF',
            stack: 'gender',
            borderRadius: 12,
          }
        ]
      };

      if(workChart){
        workChart.destroy();
      }
      
      const config = {
        type: 'bar',
        data: data,
        plugins: [ChartDataLabels],
        layouts: {
          font:{
            family:"Neue Machina"
          }
        },
        options: {
          plugins: {
            title: {
              display: true,
              text: question,
              color: 'whitesmoke',
              font: {
                size: 18,
                family:"Neue Machina"
              },
              position: 'top',
              padding: {
                top: 20,  // Add padding to the top of the title
                bottom: 30  // Add padding to the bottom of the title
              },
            },
            tooltip: {
              callbacks: {
                label: function(context) {
                  let value = context.raw;
                  let positiveValue = Math.abs(value);

                  return workIndex < 1 ? `${positiveValue}€` : `${positiveValue}%`;
                }
              }
            },
            datalabels: {
              display: true,
              align: function(context) {
                var dataIndex = context.dataIndex;
                var value = context.dataset.data[dataIndex];
                return value > 0 ? 'end' : 'start';
              },
              anchor: function(context) {
                var dataIndex = context.dataIndex;
                var value = context.dataset.data[dataIndex];
                return value > 0 ? 'end' : 'start';
              },
              clamp: true, 
              formatter: function(value, context) {
                const positiveValue = value < 0 ? value * -1 : value;
                return workIndex < 1 ? `${positiveValue}€` : `${positiveValue}%`;
              },
              font: {
                family:"Neue Machina"
              },
              padding: {
                bottom:10  // Add padding to the bottom of the title
              },
              offset: 0,      // Adjust as needed
              clamp: true,    // Ensures labels stay inside the chart area
              color: 'whitesmoke'
            },
            legend:{
              display: false
            },
          },
          responsive: true,
          interaction: {
            intersect: false,
          },
          scales: {
            x: {
              ticks: {
                display: true,
                color: '#2698BF',
                font:{
                  family:"Neue Machina",
                  weight: 800
                },
                padding: 30,
                showLabelBackdrop: true,
                backdropColor: 'whitesmoke'
              },
              offset: true,
              stacked: true,
            },
            y: {
              ticks: {
                display: false,
                color: 'whitesmoke'
              },
              stacked: false
            },
          }
        }
      };
      workChart = new Chart(document.getElementById('work-chart'), config);
    }
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS

    window.onload = function () {
      var countUp = new CountUp("pr", prishtine, {
        enableScrollSpy: true,
      });
      countUp.start();
      countUp = new CountUp("gjk", gjakova, {
        enableScrollSpy: true,
      });
      countUp.start();
      countUp = new CountUp("pj", peja, {
        enableScrollSpy: true,
      });
      countUp.start();
      countUp = new CountUp("mi", mitrovica, {
        enableScrollSpy: true,
      });
      countUp.start();
      countUp = new CountUp("pz", prizren, {
        enableScrollSpy: true,
      });
      countUp.start();
      countUp = new CountUp("fe", ferizaj, {
        enableScrollSpy: true,
      });
      countUp.start();
      countUp = new CountUp("gji", gjilan, {
        enableScrollSpy: true,
      });
      countUp.start();
      countUp = new CountUp("totalcase", total, {
        enableScrollSpy: true,
      });
      countUp.start();

      countUp.handleScroll();
    };
  },
};
