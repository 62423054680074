/* eslint-disable */
import Swiper, { Navigation, FreeMode } from "swiper";

import lightGallery from "lightgallery";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";

export default {
  init() {
    // JavaScript to be fired on the about us page
    const swiperAbout = new Swiper(".about-swiper", {
      modules: [Navigation, FreeMode],
      slidesPerView: 4,
      spaceBetween: 30,
      freeMode: true,
      draggable: true,
      navigation: {
        nextEl: ".swiper-arrows__next",
        prevEl: ".swiper-arrows__prev",
      },
      // Responsive breakpoints
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: "auto",
          spaceBetween: 20,
          fixedWidth: "17.4375rem",
        },
        // when window width is >= 480px
        990: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        // when window width is >= 640px
        1199: {
          slidesPerView: 4,
          spaceBetween: 40,
        },
      },
    });

    lightGallery(document.getElementById("lightgallery"), {
      plugins: [lgZoom, lgThumbnail],
      speed: 500,
    });

    $("#show-all-photos").on("click", () => {
      $("#child-1").trigger("click");
    });

    // aboutSwiper();
  },
};
