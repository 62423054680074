/* eslint-disable */
export default {
  init() {
    if ($(".sub-menu")[0]) {
      $(".wpml-ls-native").addClass("arrow-down");
    } else {
      $(".wpml-ls-native").removeClass("arrow-down");
    }

    // $(".arrow-down").hover(
    //   () => {
    //     $(".sub-menu").addClass("sub-active");
    //   },
    //   () => {
    //     $(".sub-menu").removeClass("sub-active");
    //   }
    // );
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
